
<script setup>
    import { inject } from "vue";

    import MContainer from "./components.Container.vue";
    import MContent from "./components.Content.vue";

    const props = defineProps({
        name: { type: String, required: true },
        is  : { type: Object, required: true },
    });

    const tab = inject("tab");
</script>

<template>
    <transition name="fade-100">
        <MContainer v-if="tab === props.name" :key="props.name" >
            <MContent>
                <slot>
                    <component :is="props.is" v-bind="$attrs" />
                </slot>
            </MContent>
        </MContainer>
    </transition>
</template>
